
.calendar {
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }

}

.calendar-main {
  position: relative;
  max-width: 1400px;

  &.loading {
    .fc-view-harness {
      opacity: .5;
    }
  }

  .fc-media-screen {
    max-height: 800px;
  }

  //style columns of the calendar grid
  .fc-timegrid-col {
    border-right: 1px solid #DFE0EB!important;
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }

  //border for calendar
  .fc-view-harness {
    border-radius: 20px;
    border: 1px solid #DFE0EB;
  }

  //styles to header toolbar
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 25px;
    .fc-toolbar-title {
      font-size: 14px;
    }
  }

  //styles to calendar header
  .fc-scrollgrid-section-header {
    .fc-scroller-harness {
      background-color: #F1F3F6;
      border-radius: 20px 20px 0 0;
    }
  }

  //events
  div.fc-timegrid-event-harness {
    //border-left: 2px solid #3751FF;
    //border: 1px solid #FFFFFF
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    //height: calc(100% + 2px);
    box-shadow: 0 0 0 0;
    //background-color: #E1E5FF;
    //background-color: rgba(225,229,255, 0.5);
    border-radius: 6px;
  }

  .fc-timegrid-event {
    margin-bottom: 0;
  }

  .fc-v-event {
    border: none;
  }

  .noFocusTime {
    display: none;
    .event {
      &-time {
        display: none;
      }
    }
  }

  .fc-timegrid-event-short {
    .fc-event-main {
      padding: 0 2px;
      .event {
        //display: flex;
        .event-title {
          text-overflow: ellipsis;
          //width: 80%;
          overflow: hidden;
          white-space: nowrap;
        }
        .event-time {
          display: none;
          width: 30px;
        }
      }
    }
  }

  .fc-event-main {
    border-radius: 5px;
    border: 1px solid #ABB1DC;
    padding: 6px 9px;
    color: #18181B;
    overflow-y: scroll;
    font-size: 11px;
    font-weight: 500;

    .event {
      &-title {
        color: #18181B;
      }
      &-time {
        color: #464649;
      }
    }
  }

  //hide 00:00 time in calendar
  .fc-timegrid-slot[data-time="00:00:00"] {
    div.fc-timegrid-slot-label-frame {
      div.fc-timegrid-slot-label-cushion {
        display: none;
      }
    }
  }

  //style column with day time
  .fc-col-header {
    colgroup {
      col {
        width: 60px !important;
      }
    }
  }

  .fc-timegrid-slots {
    table {
      colgroup {
        col {
          width: 45px !important;
        }
      }
    }
  }

  .fc-timegrid-cols {
    table {
      colgroup {
        col {
          width: 60px !important;
        }
      }
    }
  }

  //delete borders for table
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  //specify border for calendar cells and rows
  .fc-theme-standard td, .fc-theme-standard th {
    border: none;
  }

  tr {
    height: 28px!important;

    td.fc-timegrid-slot-lane.fc-timegrid-slot-minor {
      border-bottom: 1px solid lightgrey !important;
    }
  }

  //day header
  .fc .fc-col-header-cell-cushion {
    .day-header {
      padding: 1px 4px;
      div {
        &:first-child {
          font-size: 10px;
        }
        &:last-child {
          font-size: 15px;
        }
      }
    }
  }

  //change color for today day header
  .fc-day-today {
    color: #3751FF;
  }

  .fc .fc-timegrid-slot-minor {
    border: none;
  }

  //time slot cell height
  tr {
    height: 50px;
  }

  //move upper slot time
  td.fc-timegrid-slot.fc-timegrid-slot-label {
    position: relative;
  }

  .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: -7px;
    right: 1px;
    color: #18181B;
  }

  //delete scrollbars
  .fc-scroller::-webkit-scrollbar {
    display: none;
  }

  .fc-toolbar-chunk {
    div {
      &:first-child {
        display: flex;
        align-items: center;
        button {
          &:active {
            background-color: transparent;
          }
        }
      }
    }
  }
  //buttons
  .fc .fc-button {
    box-shadow: none;
  }
  .fc .fc-button .fc-icon {
    font-size: 18px;
  }
  .fc-button-group {
    background-color: #F1F3F6;
    color: #9FA2B4;
    border-radius: 10px;
    button {
      font-size: 12px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 5px 18px;
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .fc .fc-button-primary {
    background-color: #F1F3F6;
    border-radius: 0;
    color: #18181B;
    border: none;
    height: 28px;
    opacity: 1;

    &.fc-today-button {
      padding: 0;
      width: 50px;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 700;
      &:active {
        background-color: #F1F3F6;
        color: #3751FF;
      }
    }
    &.fc-prev-button {
      padding: 0 10px;
      border-radius: 10px 0 0 10px;
      &:active {
        background-color: #F1F3F6;
        color: #3751FF;
      }
      .fc-icon-chevron-left {
        margin-bottom: 3px;
      }
    }
    &.fc-next-button {
      padding: 0 10px;
      border-radius: 0 10px 10px 0;
      margin-right: 20px;
      &:active {
        background-color: #F1F3F6;
        color: #3751FF;
      }
      .fc-icon-chevron-right {
        margin-bottom: 3px;
      }
    }

    &:active {
      border: none;
      box-shadow: none!important;
      background-color: #d7dcff;
      color: #3751FF;
    }
    &:focus {
      box-shadow: none!important;
    }
  }
  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #d7dcff;
    color: #3751FF;
  }

  //timegrid now indicator line

  .fc-timegrid-now-indicator-line {
    border: none;
    height: 1px;
    background-color: #E34C70;
    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }
}

.calendar-secondary {

  &.loading {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        opacity: .3;
      }
    }
    .fc-scrollgrid-section-body {
      opacity: .3;
    }
  }

  background-color: #18181B;

  .fc-theme-standard td, .fc-theme-standard th {
    border: none;
    width: 34px;
    height: 24px;
    cursor: pointer;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-col-header-cell {
    color: lightgrey;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    display: none;
  }

  //styles for clicked date
  .fc .fc-daygrid-day-bg .fc-highlight {
    background-color: transparent;
    max-height: 24px;
    max-width: 34px;
  }

  //buttons
  .fc .fc-button {
    box-shadow: none;
  }
  .fc .fc-button-primary {
    border: none;
    background-color: transparent;
    color: white;
    padding: 0;

    &:active,
    &:focus {
      border: none;
      box-shadow: none!important;
      background-color: transparent;
      color: white;
    }
  }

  .fc .fc-toolbar-title {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
  }

  .day-header {
    color: #71717A;
    font-weight: 700;
    font-size: 10px;
    &.today {
      color: #3751FF;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;

    .fc-daygrid-day-number {
      div {
        border-radius: 20px!important;
      }
    }

    .fc-daygrid-day-top {
      border-radius: 20px;
    }

    .fc-highlight {
      border-radius: 20px;
    }
  }

  .fc .fc-daygrid-day-top {
    justify-content: center;
    //background-color: #3751FF;
    border-radius: 2px;
    max-width: 34px;
    height: 24px;
    margin: 0 auto;
  }
  .fc .fc-daygrid-day-number {
    padding: 0;
    color: white;
    font-size: 11px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    div {
      border-radius: 2px;
    }
  }
}

