.daily-metric {
  position: relative;
  //width: 255px;
  //min-width: 255px;
  height: 178px;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 20px;
  padding: 20px;
  //margin: 0 10px;
  cursor: pointer;

  .upper-part {
    min-height: 60px;
    text-align: right;
    .progress-bar {
      position: relative;
      width: 100%;
      height: 6px;
      background-color: #DFE0EB;
      border-radius: 5px;

      .value {
        position: absolute;
        height: 100%;
        background-color: #000000;
        border-radius: 5px;
        transition-duration: 1s;
      }
    }
  }

  .metric-name {
    color: #18181B;
    display: inline-flex;
    align-items: center;
  }

  &.active {
    border-color: #3751FF;

    .metric-value {
      color: #3751FF;
    }
    .metric-name {
      color: #3751FF;
    }
    .progress-bar {
      .value {
        background-color: #3751FF;
      }
    }
  }
  &.loading {
    border-color: #DFE0EB;
  }
}

.metric-symbol {
  width: 56px;
  margin-bottom: 15px;
}

