//@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
}
//
//body {
//  font-family: 'Mulish', sans-serif;
//}

/* mulish-regular - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/mulish-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/mulish-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/mulish-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/mulish-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/mulish-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/mulish-v5-latin-regular.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/mulish-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/mulish-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/mulish-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/mulish-v5-latin-600.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/mulish-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/mulish-v5-latin-600.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-700 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/mulish-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/mulish-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/mulish-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/mulish-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/mulish-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/mulish-v5-latin-700.svg#Mulish') format('svg'); /* Legacy iOS */
}
