.chart-container{
  //width: 1080px;
  height: 480px;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 20px;
  //margin: 0 10px;
  padding: 30px 30px 40px;

  .label {
    font-size: 14px;
    color: #000000;
    font-weight: 700;
  }

  .tooltip {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    span {
      margin: 5px 0;
    }
  }
}

//styles to yAxis xAxis ticks
.recharts-cartesian-axis-tick-value {
  font-size: 10px;
  color: #9FA2B4;
}

//hide 0 tick value on yAxis
.yAxis {
  .recharts-cartesian-axis-tick {
    &:first-child {
      .recharts-cartesian-axis-tick-value {
        display: none;
      }
    }
  }
}
